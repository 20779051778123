import { getSmartWizardStepsOrder } from './flow';

export default {
  components: {
    SmartWizardPage: () => import('@/components/Wizard/SmartWizardPage.vue'),
  },
  data: () => ({
    option: '',
  }),
  computed: {
    neededParams() {
      return getSmartWizardStepsOrder().slice(2, -2);
    },
  },
  watch: {
    option(selected) {
      if (this.next && selected) this.next(selected);
    },
  },

  methods: {
    buildSmartWizardQuery(query) {
      const params = { type: 'smart-wizard', useCases: [] };
      for (const [key, value] of Object.entries(query || this.$route.query)) {
        if (!this.neededParams.includes(key)) continue;
        let keyPath = `${key}.${value}`;
        if (key === 'buildList') {
          keyPath += `.${this.$route.query.discounts || 'no'}`;
          params.promotedKey = keyPath;
        }
        params.useCases.push(keyPath);
      }
      return params;
    },
  },
};
