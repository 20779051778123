<template lang="pug">
.om-tactics
  .om-tactics-body
    .tactics-recommendation-page-container
      .om-tactic-recommendation(
        data-track-property="eventName:admin_click|location:tactics-recommendation-page|setting:{event}"
      )
        Recommendation(
          :recommendedEasyTactics="getRecommendedTactics"
          :selectedTheme="selectedTheme"
          :customTheme="customTheme"
          :tacticsLoading="tacticsLoading"
          :customThemeLoading="customThemeLoading"
          @showSimilarTemplates="showSimilarTemplates"
          @showTemplatePreview="showTemplatePreview"
        )
  sticky-bottom-bar(:showBackBtn="false" @templates="navigateToTemplates")
  Modals
</template>
<script>
  import { mapActions, mapGetters } from 'vuex';
  import GET_CUSTOM_THEMES from '@/graphql/GetCustomThemes.gql';
  import paletteMixin from '@/mixins/palette';
  import ssrMixin from '@/mixins/ssr';

  import { multiColorThemes } from '../Wizard/consts';

  import sharedMixin from '../Wizard/shared';
  import smartWizardMixin from '../Wizard/smartWizard';

  export default {
    components: {
      Recommendation: () => import('@/components/Tactics/Recommendation.vue'),
      Modals: () => import('@/components/Tactics/Modals.vue'),
      StickyBottomBar: () => import('@/components/Tactics/StickyBottomBar.vue'),
    },
    mixins: [paletteMixin, ssrMixin, sharedMixin, smartWizardMixin],

    data() {
      return {
        tacticsLoading: false,
        selectedColor: '',
        theme: 'Clean',
        customThemeLoading: true,
        customTheme: undefined,
      };
    },

    computed: {
      ...mapGetters(['wizardPreferences', 'getRecommendedTactics']),
      themes() {
        return multiColorThemes.map((t) => ({
          key: t,
          value: t,
        }));
      },

      style() {
        return this.wizardPreferences ? this.wizardPreferences.style || null : null;
      },

      selectedTheme() {
        return this.$route.query.theme || this.style;
      },

      themeKit() {
        return this.customTheme?.themeKit;
      },
    },

    watch: {
      async selectedTheme(v) {
        await this.updateWizardPreferences({ style: v });
      },
      customThemeLoading() {
        this.$nextTick(() => {
          if (!this.customThemeLoading) {
            this.$store.commit('showAdminLoader', false);
          }
          this.updateDimensions();
        });
      },
    },

    created() {
      this.$emit('loaderAnimationEnd');
      this.initiate();
    },

    methods: {
      ...mapActions(['fetchRecommendedTactics']),
      async initiate() {
        await this.fetchRecommendedUseCases();
        this.$store.commit('showAdminLoader', true);
        await this.setThemeKit();
      },
      async setThemeKit() {
        this.customThemeLoading = true;
        const {
          data: { themes },
        } = await this.$apollo.query({
          query: GET_CUSTOM_THEMES,
        });
        this.customTheme = themes?.custom?.find(({ _id }) => _id === this.$route.query.customTheme);
        this.customThemeLoading = false;
      },

      async fetchRecommendedUseCases() {
        if (!this.getRecommendedTactics.length) {
          this.tacticsLoading = true;
          await this.fetchRecommendedTactics();
          this.tacticsLoading = false;
        }
      },
      async showTemplatePreview({ useCase, template }) {
        // await this.updateWizardPreferences({ useCase: useCase._id });
        const { color, coupon, goal, list } = this.$route.query;
        this.$modal.show('template-preview', {
          templateId: template._id,
          color: this.themeKit ? undefined : color,
          themeKit: this.themeKit ? this.themeKit : undefined,
          isWizard: true,
          heapTracking: {
            name: 'template-recommendation-selection-wizard',
            data: {
              useCaseName: useCase.name.en,
              color,
              coupon,
              goal,
              list,
              theme: template.theme,
            },
          },
        });
      },
      async showSimilarTemplates({ useCase }) {
        // await this.updateWizardPreferences({ useCase: useCase._id });
        return this.$router.push(this.getSimilarTemplatesRoute({ useCase }));
      },
      getSimilarTemplatesRoute({ useCase }) {
        return {
          name: 'tactic_templates',
          params: {
            id: useCase._id,
          },
          query: {
            customTheme: this.$route.query.customTheme,
            theme: this.$route.query.theme,
          },
        };
      },
      navigateToTemplates() {
        this.$router.push({ name: 'templates' });
      },
    },
  };
</script>

<style lang="sass">
  .om-tactic-recommendation
    padding-bottom: 3.5rem

    &-style-chooser
      position: sticky
      top: 24px
      padding: 12px 0 24px
      border-radius: 8px
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1), 0 4px 16px rgba(0, 0, 0, 0.04)
      margin-right: 12px

      &-title
        font-size: 18px
        line-height: 28px
        font-weight: 500
        color: #8F97A4
        padding: 12px

  .om-tactic-more-advanced-tactics
    margin: auto
    background: #FFEFE5
    padding: 15px
    position: relative
    overflow: hidden

    .text-line-1
      font-weight: 700
      font-size: 1.5rem

    .text-line-2
      font-weight: 700
      font-size: 1rem

  .om-tactic-more-tactics-bg-graph
    position: absolute
    height: 100%
    width: auto
    right: min(20rem, 21%)
</style>
